export enum ResidentialDropdownSuggession {
  countryId = "countryId",
  tariffType = "tariffType",
  state = "state",
  city = "city",
}

export class FilterOption {
  page?: number;
  size?: number;
  total?: number;
  result?: any;
}

export interface OptionItem {
  id: number;
  label: string;
  typeName?: string;
  icon?: string;
}

export interface ResidenceForm {
  residenceName: string;
  ownerFullName: string;
  address1: string;
  address2: string;
  countryId: number;
  countryName: string;
  stateCityEmirateId: number;
  stateCityEmirateName: string;
  residenceMeterTypeId: number;
  location: string;
  locationLatitude: string;
  locationLongitude: string;
  isBoughtQLensLite: boolean;
  isBoughtQLensPlus: boolean;
}

export interface ResidenceData {
  id: number;
  residenceName: string;
  ownerFullName: string;
  address1: string;
  address2: string;
  countryId: number;
  countryName: string;
  stateCityEmirateId: number;
  stateCityEmirateName: string;
  geoCode: GeoCode;
  residenceMeterTypeId: number;
  location: string;
  locationLatitude: string;
  locationLongitude: string;
  isBoughtQLensLite: boolean;
  isBoughtQLensPlus: boolean;
  residenceNumberWithPrifix: string;
  tariffType: number;
  ownershipType: number;
  isActive: boolean;
  createUserId: number;
  createUser: string;
  modifyUserId: number;
  modifyUser: string;
  createDate: Date;
  modifyDate: Date;
  stateId: number;
  stateName: string;
  cityId: number;
  cityName: string;
}

export interface GeoCode {
  north: number;
  east: number;
  south: number;
  west: number;
}

export class DataTableResidence {
  page: number;
  size: number;
  total: number;
  data: ResidenceData[];
}

export const Product = [
  {
    id: 1,
    label: "Qlens Lite",
  },
  {
    id: 2,
    label: "Qlens Plus",
  },
];

export enum EKeyField {
  ownerFullName = "ownerFullName",
  residenceName = "residenceName",
  residenceNumberWithPrifix = "residenceNumberWithPrifix",
  residenceMeterTypeId = "residenceMeterTypeId",
  isActive = "isActive",
}

export enum MDMTabKey {
  residence = 0,
  device = 1,
  group = 2,
  unitCost = 3,
}

export const ResidenceMeterType = [
  {
    id: 1,
    label: "Single Phase",
  },
  {
    id: 2,
    label: "Three Phase",
  },
];

export enum patternValidation {
  patternAllowAlphabets = "^[a-zA-Z ]*$",
}

export enum ResidenceStatusType {
  Enabled = "Enabled",
  Disabled = "Disabled",
}

export enum OwnerShip {
  Citizen = 1,
  Residence = 2,
}

export const OwnerShipType = [
  {
    id: 1,
    label: "Citizen",
  },
  {
    id: 2,
    label: "Resident",
  },
];
