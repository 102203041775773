import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CONSTANTS } from "../constants/constants";
import { catchError } from "rxjs";
import { ListCustom } from "../models";
import {
  GroupPerformanceDataReq,
  PowerQualityGroupReq,
  PowerQualitySiteReq,
  SitePerformanceDataReq,
} from "../models/performance";

@Injectable({
  providedIn: "root",
})
export class PerformanceService {
  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private _http: HttpClient) {}
  //This API used for get peer performance based on group id.
  getPeerPerformance(id: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.PEER_PERFORMANCE}/${id}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
  //This API used for get site performance based on group id.
  getSitePerformance(id: number, data?: ListCustom) {
    const params = {
      id,
      ...data,
    };
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.SITE_PERFORMANCE}`,
        { params: params, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get map overview details.
  getMapOverviewDetails(id: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.MAP_VIEW}/${id}`,
        { ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get group performance list based on group id.
  getGroupPerformanceList(data: GroupPerformanceDataReq, params?: ListCustom) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.GROUP_PERFORMANCE_DATA}`,
        data,
        { params: { ...params }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get site performance list based on site id.
  getSitePerformanceList(data: SitePerformanceDataReq, params?: ListCustom) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.SITE_PERFORMANCE_DATA}`,
        data,
        { params: { ...params }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get efficiency group performance list based on group id.
  getGroupEfficiencyPerformanceList(
    data: GroupPerformanceDataReq,
    params?: ListCustom,
  ) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.GROUP_EFFICIENCY_PERFORMANCE_DATA}`,
        data,
        { params: { ...params }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get site Efficiency performance list based on site id.
  getSiteEfficiencyPerformanceList(
    data: SitePerformanceDataReq,
    params?: ListCustom,
  ) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.SITE_EFFICIENCY_PERFORMANCE_DATA}`,
        data,
        { params: { ...params }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get power quality group performance list based on group id.
  getGroupPowerQualityPerformanceList(
    data: PowerQualityGroupReq,
    params?: ListCustom,
  ) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.GROUP_POWER_QUALITY_PERFORMANCE_DATA}`,
        data,
        { params: { ...params }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get power quality site performance list based on site id.
  getSitePowerQualityPerformanceList(
    data: PowerQualitySiteReq,
    params?: ListCustom,
  ) {
    return this._http
      .post(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.SITE_POWER_QUALITY_PERFORMANCE_DATA}`,
        data,
        { params: { ...params }, ...this.httpOptions },
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }

  //This API used for get group overview ranking against global.
  getRankingAgainstGlobalDetail(id: number) {
    return this._http
      .get(
        `${environment.baseAPIURL}${CONSTANTS.API.PERFORMANCE.GROUP_RANKING_AGAINST_GLOBAL}/${id}`,
        this.httpOptions,
      )
      .pipe(
        catchError((e) => {
          throw e;
        }),
      );
  }
}
