export interface Series {
  id?: number;
  name: string;
  key: string;
  color: string;
  active: boolean;
  img?: string;
  seriesColor?: boolean;
}

export interface Graph {
  name: string;
  key: string;
  color: string;
  active: boolean;
  img?: string;
}

export enum CommonChartColor {
  Label = "#B0AEC0",
  GridLine = "#6B71EE",
  Scroll = "#211f4d",
  Thumb = "#6b71ee",
}

export const CommonChartSettings = {
  LineShadowStrokeWidth: 3,
  LineShadowBlur: 1,
  LineShadowOffsetX: 0,
  LineShadowOffsetY: 4,
  LineShadowOpacity: 0.2,
  BulletPointRadius: 4,
  LabelFontSize: "14px",
  MinGridDistance: 50,
  LineStrokeWidth: 1,
  SeriesAnimationDuration: 1500,
  ChartAnimationDuration: 1500,
  ChartAnimationDelay: 200,
  tooltipBGColor: "#FFF",
  emptySeriesColor: "#211C45",
};

export const BarChartSettings = {
  cornerRadiusBL: 6,
  cornerRadiusBR: 6,
  cornerRadiusTL: 6,
  cornerRadiusTR: 6,
};

export const PieChartSettings = {
  tooltipBGColor: "#FFF",
  tooltipStroke: "#c9c9c9",
  radius: 90,
  innerRadius: 30,
};

export const DonutChartSettings = {
  innerRadius: 92,
  radius: 100,
  seriesRadius: 80,
  cornerRadius: 3,
};

export const GaugeChartSettings = {
  innerRadius: 60,
  startAngle: 0,
  endAngle: 360,
  minGridDistance: 50,
  xRadius: 80,
  xMin: 0,
  xMax: 100,
  cornerRadius: 30,
};

export const HorizontalColumnBarChartSettings = {
  columnHeight: 15,
  yMaxWidth: 100,
  xMin: 0,
  xMax: 100,
};

export const CategoryAxisName = "labelXAxis";
export const ValueXField = "value";
export const ValueEmptyXField = "full";
export enum EnergyChartColor {
  kWhUsage = "#6B71EE",
  Solar = "#28C3F9",
  Import = "#6BF28E",
  Export = "#ED6E6E",
}

export const EnergySeries: Series[] = [
  {
    name: "kWh Usage",
    key: "graphUsage",
    color: EnergyChartColor.kWhUsage,
    active: true,
  },
  {
    name: "Solar",
    key: "generated",
    color: EnergyChartColor.Solar,
    active: true,
  },
  {
    name: "Import",
    key: "imported",
    color: EnergyChartColor.Import,
    active: true,
  },
  {
    name: "Export",
    key: "exported",
    color: EnergyChartColor.Export,
    active: true,
  },
];

export enum CarbonChartColor {
  KgCo2 = "#EFC36C",
  solar = "#28C3F9",
}

export const CarbonSeries: Series[] = [
  {
    name: "KgCo2",
    key: "kgCo2",
    color: CarbonChartColor.KgCo2,
    active: true,
  },
  {
    name: "Solar",
    key: "usage",
    color: CarbonChartColor.solar,
    active: true,
  },
];

export enum FinancialChartColor {
  Financial = "#6BF28E",
  Solar = "#28C3F9",
}

export const FinancialSeries: Series[] = [
  {
    name: "Financial",
    key: "graphKwHUsage",
    color: FinancialChartColor.Financial,
    active: true,
  },
  {
    name: "Solar",
    key: "imported",
    color: FinancialChartColor.Solar,
    active: true,
  },
];

export const LinearGradiantArr = [
  {
    opacity: 0.3,
  },
  {
    opacity: 0.0,
  },
];

export enum SelfSufficiencyChartColor {
  kWhUsage = "#6B71EE",
  Solar = "#28C3F9",
}

export const SelfSufficiencySeries: Series[] = [
  {
    name: "kWh Usage",
    key: "used",
    color: SelfSufficiencyChartColor.kWhUsage,
    active: true,
  },
  {
    name: "Solar",
    key: "generated",
    color: SelfSufficiencyChartColor.Solar,
    active: true,
  },
];

export enum PieChartAngle {
  startAngle = 170,
  endAngle = 370,
}
