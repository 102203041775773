import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { AnalyticsState } from "src/main/modules/analytics/state/analytics.reducer";
import { DataLogState } from "src/main/modules/data-log/state/data-log.reducer";
import { LoginState } from "src/main/modules/login/state/login-reducer";
import { ReportState } from "src/main/modules/report/state/report.reducers";

export interface AppState {}

export const initialAppState: AppState = {};

export const reducers: ActionReducerMap<AppState> = {};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    console.log("state before: ", state);
    console.log("action", action);
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<{
  login: LoginState;
  datalog: DataLogState;
  analytics: AnalyticsState;
  reporting: ReportState;
}>[] = !environment.production ? [logger] : [];
